// @file Browser window object with added type declaration for our app.
/// <reference types="@types/gapi" />
import type { UserRole } from '@@/bits/user_model'
import type { NativeBridgeWindow, NativeBridgeWW } from '@@/native_bridge/types'
import type { DeviceDetails, Tenant, User } from '@@/types'
import type { BrowserJsPlumbInstance } from '@jsplumb/browser-ui'
import type { QueryClient } from '@tanstack/vue-query'
import type { Channel, Socket } from 'phoenix'
import type { Editor, useMenuClipboardEvents } from 'tldraw'
import type Vue from 'vue'
import type { Store } from 'vuex'

interface WW extends NativeBridgeWW {
  uid?: any
  vueStartingState?: any
  device?: DeviceDetails
  flip?: any
  experiment?: {}
  xstore?: Store<any>
  // Padlet Drive page
  arvoConfig?: any
  // For backend recaptchas
  executeRecaptcha?: (any) => Promise<string>
  startingStatePath?: string
  // Brahms data
  brahmsStartingState?: any
  brahms?: {
    isUsingLongPolling?: boolean
    socket?: Socket
    channels?: {
      wall: Channel
      device: Channel
      user: Channel
    }
    msgRefs?: {
      wall: number
      device: number
    }
    messageQueue?: any[]
  }
}

/**
 * The ResizeObserver interface is used to observe changes to Element's content
 * rect.
 *
 * It is modeled after MutationObserver and IntersectionObserver.
 */
export interface ResizeObserver {
  new (callback: ResizeObserverCallback)

  /**
   * Adds target to the list of observed elements.
   */
  observe: (target: Element) => void

  /**
   * Removes target from the list of observed elements.
   */
  unobserve: (target: Element) => void

  /**
   * Clears both the observationTargets and activeTargets lists.
   */
  disconnect: () => void
}

/**
 * This callback delivers ResizeObserver's notifications. It is invoked by a
 * broadcast active observations algorithm.
 */
export type ResizeObserverCallback = (entries: ResizeObserverEntry[], observer: ResizeObserver) => void

export interface ResizeObserverEntry {
  /**
   * @param target The Element whose size has changed.
   */
  new (target: Element)

  /**
   * The Element whose size has changed.
   */
  readonly target: Element

  /**
   * Element's content rect when ResizeObserverCallback is invoked.
   */
  readonly contentRect: DOMRectReadOnly
}

interface DOMRectReadOnly {
  fromRect: (other: DOMRectInit | undefined) => DOMRectReadOnly

  readonly x: number
  readonly y: number
  readonly width: number
  readonly height: number
  readonly top: number
  readonly right: number
  readonly bottom: number
  readonly left: number

  toJSON: () => any
}

export interface ProfileTarget {
  id: number
  type: 'user' | 'team'
  bio: string
  name: string
  avatar: string
  role?: UserRole
  username?: string
  displayName?: string
  isNative?: boolean
  slug?: string
}

export interface ProfilePage {
  editProfileUrl: string
  authUrl: string
  isCurrentUserRegistered: boolean
  canIEditProfile: boolean
  isPayingProfile: boolean
  followerString?: string
  followerStatus?: string
}

interface BrowserWindow extends NativeBridgeWindow {
  electron?: any
  safari?: any
  PushManager?: any
  MediaRecorder?: any
  $pepinTraits?: any
  LogRocket?: any
  ww: WW
  wwStartingState: () => any
  app?: Vue
  queryClient?: QueryClient
  google: any // from asynchronous Google Javascript API, currently only Maps API (in google_map.ts)
  ResizeObserver: ResizeObserver
  $device?: DeviceDetails
  ApplePaySession: {
    // Safari only
    canMakePayments: () => boolean
    STATUS_SUCCESS
    STATUS_FAILURE
  }
  gapi?: typeof gapi
  visualViewport: any
  $currentUser?: User
  $currentTenant?: Tenant
  jsPlumbInitiated: boolean
  jsPlumbInstance?: BrowserJsPlumbInstance
  chrome?: {
    app?: object
    desktopCapture?: {
      /**
       * Shows desktop media picker UI with the specified set of sources.
       * @param sources Set of sources that should be shown to the user.
       * @param callback The callback parameter should be a function that looks like this:
       * function(string streamId) {...};
       * Parameter streamId: An opaque string that can be passed to getUserMedia() API to generate media stream that corresponds to the source selected by the user. If user didn't select any source (i.e. canceled the prompt) then the callback is called with an empty streamId. The created streamId can be used only once and expires after a few seconds when it is not used.
       */
      chooseDesktopMedia: ((sources: string[], callback: (streamId: string) => void) => number) &
        ((sources: string[], targetTab: any, callback: (streamId: string) => void) => number)

      /**
       * Hides desktop media picker dialog shown by chooseDesktopMedia().
       * @param desktopMediaRequestId Id returned by chooseDesktopMedia()
       */
      cancelChooseDesktopMedia: (desktopMediaRequestId: number) => void
    }
  }

  // Profile
  $profileTarget?: ProfileTarget
  $profilePage?: ProfilePage

  // Canvas
  tldrawMenuClipboardEvents?: ReturnType<typeof useMenuClipboardEvents>
  $tldrawEditor?: Editor
}

const browserWindow = window as unknown as BrowserWindow

export const ww = (function getWw(): WW {
  if (!browserWindow) return {}
  if (!browserWindow.ww) {
    browserWindow.ww = {}
  }
  return browserWindow.ww
})()
export default browserWindow

// Uniquely identifies current browser tab etc.
// See https://developer.mozilla.org/en-US/docs/Glossary/Browsing_context
// Note that we also have a device UID that is shared across tabs.
export const browsingContextUid = `${new Date().getTime()}${Math.random()}`.replace('.', '')

export function isInIframe(): boolean {
  return browserWindow.self !== browserWindow.top
}

/**
 * Yields to the main thread.
 *
 * This is useful for ensuring that the browser main thread is not blocked by any long tasks.
 * Helps to mitigate high TBT (Total Blocking Time), which contributes negatively to the Core Web Vitals.
 * Implementation from https://web.dev/articles/optimize-long-tasks
 *
 * @returns A promise that resolves when the main thread is yielded to.
 */
export async function yieldToMain(): Promise<void> {
  if (globalThis.scheduler?.yield) {
    return globalThis.scheduler.yield()
  }

  // Fall back to empty promise for browsers that don't support scheduler.yield
  return await Promise.resolve()
}
